import * as THREE from 'three';

const modelData = (path) => {
    if(path.indexOf('-lg') > -1)
        return {
            radius: 101.5,
            height: 670.5,
            initialRotation: -1.55,
        }

    if(path.indexOf('-md') > -1)
        return {
            radius: 101.5 * 0.96,
            height: 550,
            initialRotation: -1.55,
        }

    if(path.indexOf('-sm') > -1)
        return {
            radius: 101.5 * 0.96,
            height: 252,
            initialRotation: 2.55,
        }

    return {
        radius: 0,
        height: 0,
        initialRotation: 0,
    }
}

export default class ProductModel {
    constructor(rendererWrap) {
        const scene = new THREE.Scene();
        const {width, height} = rendererWrap.getBoundingClientRect()
        const camera = new THREE.PerspectiveCamera(40, width / height, 50, 10000);
        camera.position.set(0, 420, 1050);
        const renderer = new THREE.WebGLRenderer({
            alpha: true,
            transparent: true,
            antialias: true,
        });

        const onResize = () => {
            const {width, height} = rendererWrap.getBoundingClientRect()
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
            renderer.setSize(Math.floor(width), Math.floor(height));
            if(this._dispose) {
                requestAnimationFrame(() => this.render())
            }
        };

        onResize();

        rendererWrap.appendChild(renderer.domElement);

        const dispose = () => {
            this._dispose && this._dispose();
            // rendererWrap.removeChild(renderer.domElement); fixme: dispose of this
        }

        const textureLoader = new THREE.TextureLoader();

        const rotate = (value, size = 'lg') => {
            const imgPostfix = '-' + size
            const {initialRotation} = modelData(imgPostfix);
            const delta = Math.PI * 2
            const rotation = initialRotation + delta * (value / 100)
            const mesh = this.scene.children.filter((child) => child.name.indexOf(imgPostfix) > -1);

            if (mesh.length) {
                mesh[0].rotation.y = rotation;
            }

            this.render();
        }

        Object.assign(this, {
            camera,
            renderer,
            textureLoader,
            scene,
            onResize,
            rotate,
            dispose
        });

    }

    render() {
        this.camera.lookAt(new THREE.Vector3(0, 350, 0));
        this.renderer.render(this.scene, this.camera);
    }

    renderProduct(imgs = ['/i/models/bbq-lg.jpg']) {
        if(this._dispose) {
            this._dispose()
        }
        const meshes = [];
        let cancelled = false
        this._dispose = () => {
            cancelled = true;
            meshes.forEach(mesh => this.scene.remove(mesh))
        }
        imgs.forEach((img,i) => {
            this.textureLoader.load(img, (map) => {
                const {radius, height, initialRotation} = modelData(img);

                map.minFilter = THREE.LinearFilter;

                const material = new THREE.MeshBasicMaterial({
                    map,
                    side: THREE.FrontSide,
                });

                const tubeGeometry = new THREE.CylinderGeometry(radius, radius, height, 32, 1, true);
                const meshTube = new THREE.Mesh(tubeGeometry, material);
                meshTube.name = img;

                const capGeometry = new THREE.CylinderGeometry(radius, radius, 2, 32, 1, false);
                const capMaterial = new THREE.MeshBasicMaterial({ color: 0xd9d9d9 });
                const capMesh = new THREE.Mesh(capGeometry, capMaterial);

                meshTube.rotation.y = initialRotation;
                const halfpoint = Math.ceil(imgs.length / 2)
                const even = imgs.length % 2 === 0
                const before = (i + 1) <= halfpoint
                const sign = before ? -1 : 1
                const index = ((i + 1) - halfpoint - (even && before ? 1 : 0))
                const offset = (index - (even ? sign/2 : 0)) * radius * 2.1;
                meshTube.position.set(offset, height / 2, 0);
                capMesh.position.set(offset, height - 2, 0);

                if(cancelled) return;

                this.scene.add(capMesh);
                this.scene.add(meshTube);
                meshes.push(capMesh);
                meshes.push(meshTube);

                this.render();
            });
        })
    }
}
