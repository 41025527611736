import React, {useEffect, useRef} from 'react';
import ReactModal from 'react-modal';

import Layout from 'components/Layout';

import Video from "../components/Video";
import GameChooser from "../components/GameChooser";
import Crisps3D from "../components/Crisps3D";
import GameChooserMobile from "../components/GameChooserMobile";
import Banner from "../components/Banner";
import SEO from "../components/seo";


const IndexPage = () => {
    const [modalIsOpen,setIsOpen] = React.useState(false);

    useEffect(()=>{
        let hasOpenedBefore = false;
        for(let cookie of document.cookie.split(";")) {

            if(cookie.split("=")[0].trim() === "age-verification") {
                hasOpenedBefore = true;
                if(cookie.split("=")[1] !== "true" ) {
                    setIsOpen(true);
                }

            }

        }
        if(!hasOpenedBefore) {
            document.cookie = "age-verification=false"
            setIsOpen(true);
        }
    },[])


    function closeModal() {
        document.cookie = "age-verification=true";
        setIsOpen(false);

    }


    const hoverRef = useRef(null)
    return (

        <Layout pageName="home">
            <SEO/>
            <ReactModal
                isOpen = {modalIsOpen}
                overlayClassName="AgeVerifyModalOverlay"
                className = "AgeVerifyModalContent"
                ariaHideApp={false}
                >
                <div className="AgeVerifyModalContent__Container">
                {'Посетителями сайта могут быть только \n совершеннолетние пользователи.\n Продолжая просмотр, вы подтверждаете, что вам'}
                <span className = "AgeVerifyModalContent__Container__InfoColor18">{' исполнилось 18 лет'}</span>
                <a className = "AgeVerifyModalContent__Container__button" onClick={closeModal}>ПОДТВЕРЖДАЮ</a>
                </div>
            </ReactModal>
            {/*<Banner/>*/}
            <GameChooser/>
            <GameChooserMobile/>
            <Video ref={hoverRef}/>
            <Crisps3D hoverElement={hoverRef}/>
        </Layout>
    );
};


export default IndexPage;
