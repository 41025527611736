import React, {useEffect, useState} from "react";
import Modal from "react-modal";

import TitleImg from '../assets/i/hero/title.png'
import LogoImg from '../assets/i/logo.png'
import Sprite from '../assets/i/sprite.svg'
import KracksMain from '../assets/media/video/Kracks_main.mp4';
import {safeym} from "../utils/safeym";

const inSprite = (id) => `${Sprite}#${id}`;
const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        padding: '0',
        backgroundColor: '#333333',
        border: 'unset'
    }
};
// TODO: Merge with Chery
const YoutubeVideo = ({id, previewUrl = `https://i.ytimg.com/vi/${id}/hqdefault.jpg`, ...props}) =>
    <Modal closeTimeoutMS={500} {...props}
           overlayClassName={{
               base:"VideoModalOverlay",
               afterOpen: "VideoModalOverlay__after-open",
               beforeClose: "VideoModalOverlay__before-close"
           }}
           className ={{
        base:"VideoModalContent",
        afterOpen: "VideoModalContent__after-open",
        beforeClose: "VideoModalContent__before-close"
    }}
    >
        <iframe src={`https://www.youtube.com/embed/${id}?feature=oembed&amp;autoplay=1&amp;iv_load_policy=3&rel=0`}
                style={{width:'70vw', height: '60vh'}}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0"/>
    </Modal>

const Video = React.forwardRef((props, ref) => {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if(isOpen)
            safeym('reachGoal','Video-click')
        else
            safeym('reachGoal','Video-close')
    },[isOpen])

    return (<>
        <YoutubeVideo id="-PI0aH5wtmg" isOpen={isOpen} onRequestClose={() => setIsOpen(false)}/>
        <div className="hero js-video" id="js-hero" style={{backgroundImage: 'url(/i/hero/bg.jpg)'}} ref={ref} onClick={() => setIsOpen(true)}>
        <video className="jquery-background-video desk" id="videoDesk" muted loop>
            <source src={KracksMain} type="video/mp4"/>
            <source src={KracksMain} type="video/webm"/>
            <source src={KracksMain} type="video/ogg"/>
        </video>
        <video src={KracksMain} autoPlay muted loop playsInline
               className="jquery-background-video mob" id="videoMob">
            <source src={KracksMain} type="video/mp4"/>
            <source src={KracksMain} type="video/webm"/>
            <source src={KracksMain} type="video/ogg"/>
        </video>

        <div className="hero__content" onClick={() => setIsOpen(true)}>
            <div className="hero__text-wrap">
                <h2><img src={TitleImg} alt=""/></h2>
                <p>Вкус, которому не откажешь</p>
            </div>
            <button type="button" className="hero__play-btn js-open-popup" id="video-btn" onClick={() => setIsOpen(true)}>
                <svg className="_icon-play">
                    <use xlinkHref={inSprite('play')}></use>
                </svg>
            </button>
            {/*<button type="button" className="hero__down-btn js-hero-down">*/}
            {/*    <svg className="_icon-arrow-down">*/}
            {/*        <use xlinkHref={inSprite('arrow-down')}></use>*/}
            {/*    </svg>*/}
            {/*</button>*/}
        </div>
    </div>
    </>)
})

export default Video
