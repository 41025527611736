import React, {useState} from "react";

const switchButtonIcon = require('../assets/i/game-chooser/switch_button.png')
const logo = require('../assets/i/logo.png')
const leftGirl = require('../assets/i/game-chooser/left_girl.png')
const rightGirl = require('../assets/i/game-chooser/right_girl.png')
// const rightGirl = require('../assets/i/game-chooser/right_girl.png')
const gameB1 = require('../assets/i/game-chooser/game_b_1.png')
const gameB2 = require('../assets/i/game-chooser/game_b_2.png')
const gameB3 = require('../assets/i/game-chooser/game_b_3.png')
const gameB4 = require('../assets/i/game-chooser/game_b_4.png')
const gameA1 = require('../assets/i/game-chooser/game_a_1.png')
const gameA2 = require('../assets/i/game-chooser/game_a_2.png')
const gameA3 = require('../assets/i/game-chooser/game_a_3.png')
const gameA4 = require('../assets/i/game-chooser/game_a_4.png')
const gameA5 = require('../assets/i/game-chooser/game_a_5.png')
const instLogo = require('../assets/i/game-chooser/instagram_logo.svg')
const instArrow = require('../assets/i/game-chooser/instagram_arrow.svg')

export const INFO = {
    left: {
        title: 'Видеосвидание',
        description: 'Мыть руки и работать по видеосвязи все вроде научились — а ведь так и с романтическими встречами можно. Попробуй тест-драйв видеосвидания — и даже надевать свой «дейтинг-лук» не придётся',
        button: 'Выбрать',
    },
    right: {
        title: 'Чат знакомств',
        description: 'Завязывай смотреть вебинары по пикапу.\nЗаходи в наш симулятор дейтинг-приложения,\nвыбирай персонажа и качай навыки!',
        button: 'Выбрать',
    },
}

const GameChooser = () => {
    const [isChat, setIsChat] = useState(true);

    return <div className={`game-chooser ${isChat ? 'game-chooser__right_state' : ''}`}>
        <div className="game-chooser__background" onClick={() => setIsChat(false)}>

        </div>
        <h1 className="game-chooser__title">
            Поиграй с&nbsp;Kracks
        </h1>
        <button className="game-chooser__left_background__switcher"
                onClick={() => setIsChat(!isChat)}>
            <img src={switchButtonIcon} alt=""
                 className="game-chooser__left_background__switcher__icon"/>
        </button>
        <div className="game-chooser__left_background" onClick={() => setIsChat(true)}>
        </div>

        <img className="game-chooser__logo" src={logo} alt=""/>

        {/*<div className="game-chooser__instagram">*/}
        {/*    <div className="game-chooser__instagram__text"> Крошки ждут тебя в&nbsp;instagram</div>*/}

        {/*    <img src={instArrow} alt="instArrow" className="game-chooser__instagram__arrow"/>*/}

        {/*    <div className="game-chooser__instagram__icon">*/}
        {/*        <a href="https://www.instagram.com/_kracks_chips/" target="_blank">*/}
        {/*            <img src={instLogo} alt="instLogo" className="game-chooser__instagram__img"/>*/}
        {/*        </a>*/}
        {/*    </div>*/}
        {/*</div>*/}

        <div className={`game-chooser__left_closed ${!isChat ? 'visible' : 'invisible'}`}
             onClick={() => setIsChat(true)}>
            <img className="game-chooser__left_closed__girl" src={leftGirl} alt=""/>
            <h1 className="game-chooser__left_closed__label">
                {INFO.left.title}
            </h1>
        </div>
        <div className={`game-chooser__right_opened ${!isChat ? 'visible' : 'invisible'}`}>
            <div className="game-chooser__right_opened__background"></div>
            <div className="game-chooser__right_opened__info">
                <h1 className="game-chooser__right_opened__info__title">
                    {INFO.right.title}
                </h1>
                <div className="game-chooser__right_opened__info__description">
                    {INFO.right.description}
                </div>
                <a className="game-chooser__right_opened__info__button" href="/game">
                    {INFO.right.button}
                </a>
            </div>

            <div className="game-chooser__right_opened__girl__container">
                <img className="game-chooser__right_opened__girl" src={rightGirl} alt=""/>
            </div>

            <img
                className={`game-chooser__right_opened__icon game-chooser__right_opened__icon_one ${!isChat ? 'visible' : 'invisible'}`}
                src={gameB1} alt=""/>
            <img
                className={`game-chooser__right_opened__icon game-chooser__right_opened__icon_two ${!isChat ? 'visible' : 'invisible'}`}
                src={gameB2} alt=""/>
            <img
                className={`game-chooser__right_opened__icon game-chooser__right_opened__icon_three ${!isChat ? 'visible' : 'invisible'}`}
                src={gameB3} alt=""/>
            <img
                className={`game-chooser__right_opened__icon game-chooser__right_opened__icon_four ${!isChat ? 'visible' : 'invisible'}`}
                src={gameB4} alt=""/>
        </div>

        <div className={`game-chooser__left_opened ${isChat ? 'visible' : 'invisible'}`}>

            <div className="game-chooser__left_opened__background"></div>
            <div className="game-chooser__left_opened__girl__container">
                <img className="game-chooser__left_opened__girl" src={leftGirl} alt=""/>
            </div>
            <div className="game-chooser__left_opened__info">
                <h1 className="game-chooser__left_opened__info__title">
                    {INFO.left.title}
                </h1>
                <div className="game-chooser__left_opened__info__description">
                    {INFO.left.description}
                </div>
                <a className="game-chooser__left_opened__info__button" href="/game-video-chat">
                    {INFO.left.button}
                </a>
            </div>

            <img
                className={`game-chooser__left_opened__icon game-chooser__left_opened__icon_one ${isChat ? 'visible' : 'invisible'}`}
                src={gameA1} alt=""/>
            <img
                className={`game-chooser__left_opened__icon game-chooser__left_opened__icon_two ${isChat ? 'visible' : 'invisible'}`}
                src={gameA2} alt=""/>
            <img
                className={`game-chooser__left_opened__icon game-chooser__left_opened__icon_three ${isChat ? 'visible' : 'invisible'}`}
                src={gameA3} alt=""/>
            <img
                className={`game-chooser__left_opened__icon game-chooser__left_opened__icon_four ${isChat ? 'visible' : 'invisible'}`}
                src={gameA4} alt=""/>
            <img
                className={`game-chooser__left_opened__icon game-chooser__left_opened__icon_five ${isChat ? 'visible' : 'invisible'}`}
                src={gameA5} alt=""/>
        </div>

        <div className={`game-chooser__right_closed ${isChat ? 'visible' : 'invisible'}`}
             onClick={() => setIsChat(false)}>
            <img className="game-chooser__right_closed__girl" src={rightGirl} alt=""/>
            <h1 className="game-chooser__right_closed__label">
                {INFO.right.title}
            </h1>
        </div>
    </div>
}

export default GameChooser
