import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import ProductModel from "./Crisps3DRender";
import {debounce, throttle} from "throttle-debounce";

import BgDeskImg from 'assets/i/product-crips/bg-0.png'
import BgDeskFrontImg from 'assets/i/product-crips/bg-1.png'
import BgTabletImg from 'assets/i/product-crips/tablet-bg.png'
import BgMobileImg from 'assets/i/product-crips/mobile-bg.png'

import {useParallax} from "../utils/Parallax";


const crispsData = [
    {
        title: '\nOriginal taste',
        color: '#df2931',
        colorDark: '#f1b140',
        previewSrc: require('assets/i/product/preview-1.png'),
        description: ['Слегка подсоленные чипсы, сохранившие вкус оригинального картофеля', 'Чипсы доступны в тубах 160 г и 45 г'],
        className: '_original',
        dataTubes: [require('assets/i/models/original-lg.jpg'), require('assets/i/models/original-sm.jpg')],
        tasteBgSrcs: [require('assets/i/product-bg/original-1.png'), require('assets/i/product-bg/original-2.png')],
        mobileSrc: require('assets/i/product/mobile-original.png'),
        sliderClassName: 'product__control__original-slider'
    },
    {
        title: '\nGrilled steak',
        color: '#6e1977',
        colorDark: '#e35d8c',
        previewSrc: require('assets/i/product/preview-2.png'),
        description: ['Картофельные чипсы с сочным вкусом стейка на гриле', 'Чипсы доступны в тубах 160 г'],
        className: '_steak',
        dataTubes: [require('assets/i/models/steak-lg.jpg')],
        tasteBgSrcs: [require('assets/i/product-bg/steak-1.png'), require('assets/i/product-bg/steak-2.png'),
            require('assets/i/product-bg/steak-3.png'), require('assets/i/product-bg/steak-4.png'),
            require('assets/i/product-bg/steak-6.png'), require('assets/i/product-bg/steak-5.png')],
        mobileSrc: require('assets/i/product/mobile-steak.png'),
        sliderClassName: 'product__control__steak-slider'
    },
    {
        title: '\nBarbeque',
        color: '#ffc704',
        colorDark: '#8c4dcc',
        previewSrc: require('assets/i/product/preview-3.png'),
        description: ['Картофельные чипсы со вкусом жареного мяса, приправленные пряными специями', 'Чипсы доступны в тубах 160 г и 45 г'],
        className: '_bbq',
        dataTubes: [require('assets/i/models/bbq-lg.jpg'), require('assets/i/models/bbq-sm.jpg')],
        tasteBgSrcs: [require('assets/i/product-bg/bbq-1.png'), require('assets/i/product-bg/bbq-2.png'),
            require('assets/i/product-bg/bbq-3.png'), require('assets/i/product-bg/bbq-4.png'),
            require('assets/i/product-bg/bbq-5.png')],
        mobileSrc: require('assets/i/product/mobile-bbq.png'),
        sliderClassName: 'product__control__bbq-slider'
    },
    {
        title: 'Sour\nCream & Onion',
        color: '#12ff00',
        colorDark: '#8e8ef9',
        previewSrc: require('assets/i/product/preview-4.png'),
        description: ['Картофельные чипсы с насыщенным вкусом сметаны, посыпанные зеленым луком', 'Чипсы доступны в тубах 160 г, 110 г и 45 г'],
        className: '_sour-cream-onion',
        dataTubes: [require('assets/i/models/sour-cream-onion-lg.jpg'), require('assets/i/models/sour-cream-onion-md.png'), require('assets/i/models/sour-cream-onion-sm.jpg')],
        tasteBgSrcs: [require('assets/i/product-bg/sour-cream-onion-1.png'), require('assets/i/product-bg/sour-cream-onion-2.png'),
            require('assets/i/product-bg/sour-cream-onion-3.png'), require('assets/i/product-bg/sour-cream-onion-4.png')],
        mobileSrc: require('assets/i/product/mobile-sour-cream-onion.png'),
        sliderClassName: 'product__control__sour-cream-onion-slider'
    },
    {
        title: '\nWasabi',
        color: '#84fc4f',
        colorDark: '#e0703e',
        previewSrc: require('assets/i/product/preview-5.png'),
        description: ['Картофельные чипсы с насыщенным острым вкусом пересыпанные натуральным порошком Васаби', 'Чипсы доступны в тубах 160 г'],
        className: '_wasabi',
        dataTubes: [require('assets/i/models/wasabi-lg.jpg')],
        tasteBgSrcs: [require('assets/i/product-bg/wasabi-1.png'), require('assets/i/product-bg/wasabi-2.png'),
            require('assets/i/product-bg/wasabi-3.png'), require('assets/i/product-bg/wasabi-4.png'),
            require('assets/i/product-bg/wasabi-5.png')],
        mobileSrc: require('assets/i/product/mobile-wasabi.png'),
        sliderClassName: 'product__control__wasabi-slider'
    },
    {
        title: '\nCheese',
        color: '#fff000',
        colorDark: '#00abe0',
        previewSrc: require('assets/i/product/preview-6.png'),
        description: ['Картофельные чипсы с ароматом сыра, обильно посыпанные сырной крошкой', 'Чипсы доступны в тубах 160 г и 45 г'],
        className: '_cheese',
        dataTubes: [require('assets/i/models/cheese-lg.jpg'), require('assets/i/models/cheese-sm.jpg')],
        tasteBgSrcs: [require('assets/i/product-bg/cheese-1.png'), require('assets/i/product-bg/cheese-2.png'),
            require('assets/i/product-bg/cheese-3.png'), require('assets/i/product-bg/cheese-4.png')],
        mobileSrc: require('assets/i/product/mobile-cheese.png'),
        sliderClassName: 'product__control__cheese-slider'
    },
    {
        title: '\nPaprika',
        color: '#ffae00',
        colorDark: '#19b587',
        previewSrc: require('assets/i/product/preview-7.png'),
        description: ['Картофельные чипсы с выраженным вкусом паприки, приправленные пикантными специями', 'Чипсы доступны в тубах 160 г'],
        className: '_paprica',
        dataTubes: [require('assets/i/models/paprica-lg.jpg')],
        tasteBgSrcs: [require('assets/i/product-bg/paprica-1.png'), require('assets/i/product-bg/paprica-2.png'),
            require('assets/i/product-bg/paprica-3.png'), require('assets/i/product-bg/paprica-4.png')],
        mobileSrc: require('assets/i/product/mobile-paprika.png'),
        sliderClassName: 'product__control__paprica-slider'
    },
    {
        title: '\nCrab',
        color: '#0bb9fa',
        colorDark: '#9143c3',
        previewSrc: require('assets/i/product/preview-8.png'),
        description: ['Картофельные чипсы со вкусом изысканного морского деликатеса', 'Чипсы доступны в тубах 110 г'],
        className: '_crab',
        dataTubes: [require('assets/i/models/crab-lg.jpg')],
        tasteBgSrcs: [require('assets/i/product-bg/crab-1.png'), require('assets/i/product-bg/crab-2.png'),
            require('assets/i/product-bg/crab-3.png'), require('assets/i/product-bg/crab-2.png')],
        mobileSrc: require('assets/i/product/mobile-crab.png'),
        sliderClassName: 'product__control__crab-slider'
    }
]




const CrispSlide = (data) => {
    const modelRef = useRef(null)

    const [renderer, setRenderer] = useState(null)

    useLayoutEffect(() => {
        if (modelRef.current)
            setRenderer(new ProductModel(modelRef.current))
    }, [modelRef])

    useLayoutEffect(() => {
        if (modelRef.current && renderer) {
            renderer.renderProduct(data.dataTubes);
        }
    }, [renderer, data])

    useEffect(() => {
        const onResize = debounce(300, () => {
            renderer && renderer.onResize();
        })
        onResize();
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize)
            renderer && renderer.dispose()
        }
    }, [renderer])

    const mousemoveAreaRef = useRef(null)
    const parallaxRef1 = useRef(null)
    const parallaxRef2 = useRef(null)
    const parallaxRef3 = useRef(null)
    const parallaxRef4 = useRef(null)
    const parallaxRef2_1 = useRef(null)
    const parallaxRef2_2 = useRef(null)
    const parRefCont2 = [parallaxRef2_1,parallaxRef2_2]
    const parallaxRef = [[parallaxRef1,0.02],[parallaxRef2,0.02],[parallaxRef3,0.02],
        [parallaxRef4,0.02],[parallaxRef2_1,0.09],[parallaxRef2_2,0.09]]

    const CrispsBg = () => <div className="product__crips-bg">
        <div className="product__crips-bg-down"  ref = {parallaxRef1}>
            <img src={BgDeskImg} alt=""/>
        </div>
        <div className="product__crips-bg-up"   ref = {parallaxRef2}>
            <img src={BgDeskFrontImg} alt=""/>
        </div>
        <div className="product__crips-bg-tablet"   ref = {parallaxRef3}>
            <img src={BgTabletImg} alt=""/>
        </div>
        <div className="product__crips-bg-mobile" ref = {parallaxRef4}>
            <img src={BgMobileImg} alt=""/>
        </div>
    </div>


    useParallax(mousemoveAreaRef,parallaxRef)

    return <div className={`product__slide ${data.className}`} style={{backgroundColor: data.colorDark}} ref={mousemoveAreaRef}>
        <div className="product__slide-parallax">
            <CrispsBg/>
            <div className="product__taste-bg">
                {data.tasteBgSrcs.map((src, i) => <div className="product__taste-layer" key={i} ref = {parRefCont2[i]} >
                    <img src={src} alt=""/>
                </div>)}
            </div>
        </div>

        <div className="product__content">
            <div className="product__preview">
                <div className="product__models-wrap" ref={modelRef}>
                    <img src={data.mobileSrc} alt=""/>
                </div>
                <div className="product__control">
                    <label className={`product__control__slider ${data.sliderClassName}`}>
                        <span/>
                        <input type="range" min="1" max="100" defaultValue="1" onInput={event => renderer.rotate(event.target.value)}/>
                        <span/>
                    </label>
                    {data.dataTubes.length > 2 && <label className={`product__control__slider ${data.sliderClassName}`}>
                        <span/>
                        <input type="range" min="1" max="100" defaultValue="1" onInput={event => renderer.rotate(event.target.value, 'md')}/>
                        <span/>
                    </label>}
                    {data.dataTubes.length > 1 && <label className={`product__control__slider ${data.sliderClassName}`}>
                        <span/>
                        <input type="range" min="1" max="100" defaultValue="1" onInput={event => renderer.rotate(event.target.value, 'sm')}/>
                        <span/>
                    </label>}
                </div>
            </div>

            <div className="product__info">
                <h2 className="product__title">Kracks <span style={{color: data.color}}>{data.title.split('\n').map((part,i)=><>{part}<br/></>)}</span></h2>
                <ul className="product__props">
                    {data.description.map((it, i) => <li key={i}>{it}</li>)}
                </ul>
            </div>
        </div>
    </div>
}

const Crisps3D = ({hoverElement}) => {
    const [activeSlide, setActiveSlide] = useState(0)

    const thumbnailsRef = useRef(null)
    useEffect(() => {
        if (!hoverElement.current || !thumbnailsRef.current) return

        hoverElement.current.addEventListener('mousemove', throttle(100, (e) => {
            // if (resposive.tabletV()) { return; }
            const X = e.pageX;

            let left, center, right;

            for (let thumbnail of thumbnailsRef.current.children) {
                const rect = thumbnail.getBoundingClientRect();
                const inBounds = rect.left <= X && X <= rect.right;
                if (inBounds) {
                    center = thumbnail
                    thumbnail.classList.add('_mouse-active')
                    continue;
                } else {
                    thumbnail.classList.remove('_mouse-active')
                    thumbnail.classList.remove('_mouse-sub-active')
                }

                if (right)
                    continue;

                if (center)
                    right = thumbnail;
                else
                    left = thumbnail;
            }

            left && left.classList.add('_mouse-sub-active');
            right && right.classList.add('_mouse-sub-active');
        }))
        hoverElement.current.addEventListener('mouseleave', debounce(110, () => {
            for (let thumbnail of thumbnailsRef.current.children) {
                thumbnail.classList.remove('_mouse-active')
                thumbnail.classList.remove('_mouse-sub-active')
            }
        }));
    }, [hoverElement, thumbnailsRef])

    return <div className="crisp">
        <div className="crisp__thumbnails" ref={thumbnailsRef}>
            {crispsData.map((data, i) =>
                <a
                    className={`crisp__thumbnail ${i === activeSlide ? '_active' : ''}`}
                    style={{"color": data.colorDark}}
                    onClick={() => setActiveSlide(i)}
                    key={i}
                >
                    <img src={data.previewSrc} alt=""/>
                </a>)}
        </div>

        <div className="product" id="js-products">
            <CrispSlide {...crispsData[activeSlide]}/>
        </div>
    </div>
}
export default Crisps3D
