import React from "react";
import Carousel from 'nuka-carousel';
import {INFO} from "./GameChooser";

const logo = require('../assets/i/logo.png')
const leftGirl = require('../assets/i/game-chooser/left_girl.png')
const rightGirl = require('../assets/i/game-chooser/right_girl.png')
const mobileSwipe = require('../assets/i/game-chooser/mobile_swipe.png')
const instLogo = require('../assets/i/game-chooser/instagram_logo.svg')

const GameChooserMobile = () => {
    return <Carousel className="game-chooser-mobile" disableEdgeSwiping
                     height="521px"
                     renderBottomCenterControls={props => {
                         return <div className="game-chooser-mobile__pins">
                             <div className={`game-chooser-mobile__pins__item ${props.currentSlide === 0 ? 'game-chooser-mobile__pins__item__selected_left' : ''}`}
                                  onClick={() => props.goToSlide(0)}></div>
                             <div className={`game-chooser-mobile__pins__item ${props.currentSlide === 1 ? 'game-chooser-mobile__pins__item__selected_right' : ''}`}
                                  onClick={() => props.goToSlide(1)}></div>
                         </div>
                     }}
                     renderCenterLeftControls={props => {
                         return <button className={`game-chooser-mobile__control game-chooser-mobile__left_control ${props.currentSlide !== 0 ? 'visible' : 'invisible'}`}
                                        onClick={() => props.previousSlide()}>
                             <img src={mobileSwipe} alt=""/>
                         </button>
                     }}
                     renderCenterRightControls={props => {
                         return <button className={`game-chooser-mobile__control game-chooser-mobile__right_control ${props.currentSlide !== 1 ? 'visible' : 'invisible'}`}
                         onClick={() => props.nextSlide()}>
                             <img src={mobileSwipe} alt=""/>
                         </button>
                     }}>
        <div className="game-chooser-mobile__item game-chooser-mobile__left_item">
            {/*<div className="game-chooser-mobile__item__instagram-icon">*/}
            {/*    <a href="https://www.instagram.com/kracks_chips_official/" target="_blank">*/}
            {/*        <img src={instLogo} alt="instLogo" className="game-chooser-mobile__item__instagram-icon__img"/>*/}
            {/*    </a>*/}
            {/*</div>*/}
            <img className="game-chooser-mobile__item__logo" src={logo} alt=""/>
            <h1 className="game-chooser-mobile__item__title game-chooser-mobile__left_item__title">
                Поиграй с&nbsp;Kracks
            </h1>
            <img className="game-chooser-mobile__item__icon" src={leftGirl}
                 alt=""/>
            <div className="game-chooser-mobile__item__info game-chooser-mobile__left_item__info">
                <h1 className="game-chooser-mobile__item__info__title game-chooser-mobile__left_item__info__title">
                    {INFO.left.title}
                </h1>
                <div
                    className="game-chooser-mobile__item__info__description game-chooser-mobile__left_item__info__description">
                    {INFO.left.description}
                </div>
                <a
                    className="game-chooser-mobile__item__info__button game-chooser-mobile__left_item__info__button" href="/game-video-chat">
                    {INFO.left.button}
                </a>
            </div>
        </div>


        <div className="game-chooser-mobile__item game-chooser-mobile__right_item">
            <img className="game-chooser-mobile__item__logo" src={logo} alt=""/>
            <h1 className="game-chooser-mobile__item__title game-chooser-mobile__right_item__title">
                Поиграй с&nbsp;Kracks
            </h1>
            <div className="game-chooser-mobile__item__space"></div>
            <img className="game-chooser-mobile__item__icon" src={rightGirl}
                 alt=""/>
            <div className="game-chooser-mobile__item__info game-chooser-mobile__right_item__info">
                <h1 className="game-chooser-mobile__item__info__title game-chooser-mobile__right_item__info__title">
                    {INFO.right.title}
                </h1>
                <div
                    className="game-chooser-mobile__item__info__description game-chooser-mobile__right_item__info__description">
                    {INFO.right.description}
                </div>
                <a
                    className="game-chooser-mobile__item__info__button game-chooser-mobile__right_item__info__button" href="/game">
                    {INFO.right.button}
                </a>
            </div>
        </div>
    </Carousel>
}

export default GameChooserMobile
